<template>
  <div>
    <v-container v-if="componentLoading">
      <v-row justify="center">
        <v-col xs="12" lg="6">
          <h3>
            Lender Information
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </h3>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-if="!componentLoading">
      <v-row justify="center">
        <v-col xs="12" xl="6">
          <v-expansion-panels :value="0">
            <v-expansion-panel style="background-color: transparent">
              <v-expansion-panel-header>
                <h3>
                  Lender Information
                  <v-icon
                    class="ml-4"
                    style="cursor: pointer"
                    @click="
                      $router.push({
                        path: `/dashboard/lender/edit/${lenderId}`,
                      })
                    "
                    >mdi-account-edit-outline</v-icon
                  >
                </h3>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <v-simple-table dense>
                  <thead>
                    <th :width="450"></th>
                    <th></th>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Lender Name</td>
                      <td>{{ lenderData.lenderName }}</td>
                    </tr>
                    <tr>
                      <td>Lender Type</td>
                      <td class="text-capitalize">
                        {{ lenderData.lenderType }}
                      </td>
                    </tr>
                    <tr>
                      <td>Group Name</td>
                      <td class="text-capitalize">
                        {{ lenderData.group_name }}
                      </td>
                    </tr>
                    <tr>
                      <td>Lender Active</td>
                      <td>
                        {{ lenderData.active | booleanString }}
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
                <br />
                <v-simple-table dense>
                  <thead>
                    <th :width="450"></th>
                    <th></th>
                  </thead>
                  <tbody>
                    <tr>
                      <td>BC</td>
                      <td>
                        {{ lenderData.lendBC | booleanString }}
                      </td>
                    </tr>
                    <tr>
                      <td>Alberta</td>
                      <td>
                        {{ lenderData.lendAB | booleanString }}
                      </td>
                    </tr>
                    <tr>
                      <td>Ontario</td>
                      <td>
                        {{ lenderData.lendON | booleanString }}
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>

      <v-row justify="center" class="mt-5">
        <v-col xs="12" xl="6">
          <v-expansion-panels>
            <v-expansion-panel style="background-color: transparent">
              <v-expansion-panel-header>
                <h3>
                  Lender Contacts
                  <v-icon
                    @click="
                      $router.push({
                        path: `/dashboard/lenderContacts/add`,
                        query: { lenderId: `${lenderId}` },
                      })
                    "
                    class="ml-4"
                    >mdi-account-plus-outline</v-icon
                  >
                </h3>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-simple-table dense>
                  <thead>
                    <th class="text-left pl-4">Name</th>
                    <th class="text-left pl-4">Email</th>
                    <th class="text-left pl-4">Role</th>
                    <th class="text-left pl-4">Send Apps</th>
                  </thead>

                  <tbody>
                    <tr
                      v-for="lenderContact in lenderContacts"
                      :key="lenderContact.id"
                      @click="
                        $router.push({
                          path: `/dashboard/lenderContacts/read/${lenderContact.id}`,
                        })
                      "
                    >
                      <td class="text-left text-capitalize">
                        {{ lenderContact.firstName }}
                        {{ lenderContact.lastName }}
                      </td>
                      <td class="text-left">{{ lenderContact.lastName }}</td>
                      <td class="text-left text-capitalize">
                        {{ lenderContact.contactRole }}
                      </td>
                      <td class="text-left">
                        {{ lenderContact.emailOpportunities | booleanString }}
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>

      <v-row justify="center" class="mt-5">
        <v-col xs="12" xl="6">
          <v-expansion-panels>
            <v-expansion-panel style="background-color: transparent">
              <v-expansion-panel-header>
                <h3>
                  Lender Accounts
                  <v-icon
                    @click.native.prevent="
                      $router.push({
                        path: `/dashboard/lenderAccount/add`,
                        query: { lenderId: `${lenderId}` },
                      })
                    "
                    class="ml-4"
                    >mdi-bank-outline</v-icon
                  >
                </h3>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-simple-table dense>
                  <thead>
                    <th class="text-left pl-4">Name</th>
                    <th class="text-left pl-4">Type</th>
                    <th class="text-left pl-4">Number</th>
                    <th class="text-left pl-4">Balance</th>
                  </thead>

                  <tbody>
                    <tr
                      v-for="lenderAccount in lenderAccounts"
                      :key="lenderAccount.id"
                      @click="
                        $router.push({
                          path: `/dashboard/lenderAccount/read/${lenderAccount.id}`,
                        })
                      "
                    >
                      <td class="text-left text-capitalize">
                        {{ lenderAccount.accountName }}
                      </td>
                      <td class="text-left">{{ lenderAccount.accountType }}</td>
                      <td class="text-left">
                        {{ lenderAccount.accountNumber }}
                      </td>
                      <td class="text-left">
                        {{ lenderAccount.accountBalance }}
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>

      <v-row justify="center" class="mt-5">
        <v-col xs="12" xl="6">
          <v-expansion-panels>
            <v-expansion-panel style="background-color: transparent">
              <v-expansion-panel-header>
                <h3>
                  Lender Addresses
                  <v-icon
                    @click.native.prevent="
                      $router.push({
                        path: `/dashboard/lenderAddress/add`,
                        query: { lenderId: `${lenderId}` },
                      })
                    "
                    class="ml-4"
                    >mdi-home-outline</v-icon
                  >
                </h3>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card v-for="address in lenderAddresses" :key="address.id">
                  <v-row class="mb-7">
                    <v-col cols="11">
                      <div class="mb-3 ml-4" style="font-weight: bold;">
                        {{ address.label }}
                      </div>

                      <div class="ml-9">
                        <span v-if="address.suite"
                          >#{{ address.suite }} -
                        </span>
                        {{ address.lineOne }}<br />
                        <span v-if="address.lineTwo"
                          >{{ address.lineTwo }}<br
                        /></span>
                        {{ address.city }}, {{ address.province }}
                        {{ address.postal }}
                      </div>
                    </v-col>
                    <v-col>
                      <v-icon
                        @click="
                          $router.push({
                            path: `/dashboard/lenderAddress/edit/${address.id}`,
                          })
                        "
                        >mdi-pencil</v-icon
                      >
                    </v-col>
                  </v-row>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>

      <!-- <v-row>
        <v-col class="text-center mt-5">
          <v-btn dark @click="$router.push({ path: '/dashboard/lender/list' })"
            >Back To Lender List</v-btn
          >
        </v-col>
      </v-row> -->

      <v-row justify="center" class="mt-5">
        <v-col xs="12" xl="6">
          <v-expansion-panels>
            <v-expansion-panel style="background-color: transparent">
              <v-expansion-panel-header>
                <h3>
                  Lender Notes
                  <v-icon
                    class="ml-4"
                    style="cursor: pointer"
                    @click.stop="addNote"
                    >mdi-notebook-plus-outline</v-icon
                  >
                </h3>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <insert-note
                  v-if="addNoteActive"
                  :lenderId="lenderData.id"
                  :insertToTable="'lender'"
                  @closeNote="closeNote"
                  @refreshNotes="refreshNotes"
                ></insert-note>

                <div v-if="loadingNotes">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </div>
                <div v-if="!loadingNotes">
                  <div v-for="lenderNote in lenderNotes" :key="lenderNote.id">
                    <note
                      :noteProp="lenderNote"
                      :noteType="'lenderNote'"
                      @refreshNotes="refreshNotes"
                    ></note>
                  </div>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import API from "@/plugins/API";

export default {
  name: "LenderRead",
  props: ["lenderId"],
  components: {
    insertNote: () => import("@/components/note/InsertNote.vue"),
    Note: () => import("@/components/note/Note.vue"),
  },
  data() {
    return {
      panel: 0,
      loadingNotes: false,
      addNoteActive: false,
      formLoading: false,
      componentLoading: true,
      submitError: "",
      formRules: {
        valid: false,
        required: [(value) => !!value || "This is a required field."],
      },
      lenderData: {},
      lenderContacts: {},
      lenderAddresses: {},
      lenderNotes: {},
      lenderAccounts: {},
    };
  },
  methods: {
    async refreshNotes() {
      console.log("refresh notes firing read");
      await API()
        .get(`/lenderNotes/${this.lenderId}`)
        .then((res) => {
          this.lenderNotes = res.data;
          this.loadingNotes = false;
          this.closeNote();
        })
        .catch((e) => {
          console.log("the catch block in refreshnotes is firing");
          console.log(e);
        });
    },
    addNote() {
      this.addNoteActive = true;
    },
    closeNote() {
      this.addNoteActive = false;
    },
    submitForm() {
      this.loading = true;
      this.submitError = "";

      this.$refs.form.validate();

      if (this.formRules.valid) {
        API()
          .post("/lender/add", this.lenderData)
          .then((res) => {
            this.loading = false;

            alert(JSON.stringify(res.data));
          })
          .catch((e) => {
            // alert(e);
            this.submitError = e;
            this.loading = false;
          });
      } else {
        this.loading = false;
      }
    },
  },
  mounted() {
    API()
      .get(`/lender/read/${this.lenderId}`)
      .then((res) => {
        this.lenderData = res.data.recordsets[0][0];
        this.lenderContacts = res.data.recordsets[1];
        this.lenderAddresses = res.data.recordsets[2];
        this.lenderNotes = res.data.recordsets[3];
        this.lenderAccounts = res.data.recordsets[4];
        this.lenderAddresses = res.data.recordsets[5];

        this.componentLoading = false;
      })
      .catch((e) => {
        console.log(e);
      });
  },
  filters: {
    booleanString(x) {
      if (x == true) {
        return "Yes";
      } else {
        return "No";
      }
    },
  },
};
</script>

<style scoped>
</style>